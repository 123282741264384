@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  


@keyframes overlay-opening-keyframes {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
  
@keyframes overlay-closing-keyframes {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
