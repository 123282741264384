/*
*	Global Styes
*	- DO NOT TOUCH even...if you are asked to touch.
*/

@use './placeholders' as *;
@use './reboot' as *;
@use 'styles/globals/mixins/font';
@use './animations' as *;


$primary-color: #2E303B;

$charcoal: #2E303B;
$grey: #999;
$dove: #F0F0F0;
$mist: #f9f9f9;
$white: #fff;

$indigo: #353E5D;
$ocean: #4c7b9a;
$ice: #dfe9ef;
$ice50: #f0f4f7;
$ice25: #f8fafb;

$green: #6fa33f;
$sage: #b0d493;
$pistachio: #e0eed5;
$pistachio50: #eff6e9;
$pistachio25: #f7fbf5;

$terracotta: #db613a;
$sunset: #e5a778;
$teak: #fbdfca;
$teak50: #ffefe3;
$teak25: #fff7f1;
$white: #ffffff;
$holidayred: #BC0000;

$link_color: #4c7b9a;

$vds-colors: (
  "charcoal":   $charcoal,
  "dove":       $dove,
  "mist":       $mist,
  "indigo":     $indigo,
  "ocean":      $ocean,
  "ice":        $ice,
  "green":      $green,
  "sage":       $sage,
  "pistachio":  $pistachio,
  "terracotta": $terracotta,
  "sunset":     $sunset,
  "teak":       $teak,
  "white":      $white,
  "holidayred": $holidayred,
) !default;


:root{
	color-scheme: light;
	--default-cursor: pointer;
	--border-off: none;

	--standard-width: 2000px;
	--standard-padding: 2rem;
	--standard-column-width: 100%;
	--header-padding: 4vw;

	--hr-border: #ccc;
	--standard-border: 1px solid transparent;

	--border-radius: 4px;
	--button-border-radius: 4px;

	// colors
	--primary-color: #2E303B;
	--charcoal: #2E303B;
	--grey: #999;
	--dove: #F0F0F0;
	--mist: #f9f9f9;
	--white: #fff;
	--indigo: #353E5D;
	--ocean: #4c7b9a;
	--ice: #dfe9ef;
	--ice50: #f0f4f7;
	--ice25: #f8fafb;
	--green: #6fa33f;
	--sage: #b0d493;
	--pistachio: #e0eed5;
	--pistachio50: #eff6e9;
	--pistachio25: #f7fbf5;
	--terracotta: #db613a;
	--sunset: #e5a778;
	--teak: #fbdfca;
	--teak50: #ffefe3;
	--teak25: #fff7f1;
	--white: #ffffff;
	--holidayred: #BC0000;

	--link_color: #4c7b9a;

	--font-stack: var(--font-montserrat), Helvetica, Arial, sans-serif !important;
	--font-stack-serif: var(--font-theSeasons), "the-seasons", Georgia, Times, serif !important;

	--letter-spacing: 0.05rem;
	--font-size-base: 1.6rem;
	--font-size-mobile: calc(var(--font-size-base) - 0.4); // ~1.6rem

	--star-size: 15px;
    --star-color: #ddd;
    --star-background: #fc0;
	--star-orangebackground: #E5A778;
	--star-bluebackground: #4c7b9a;
	--toastify-toast-background: #222 !important;

	--background: #89abc1;

	--grid-gutter-width: 30px !important;
	--grid-columns: 11;


	// buttons
	--btn-font-weight: lighter;

	--box-shadow: 0 .5rem 1rem rgba(#000, .15) !important;
	--box-shadow-sm: 0 .125rem .25rem rgba(0,0,0,.075) !important;
	--box-shadow-lg: 0 1rem 3rem rgba(0,0,0,.175) !important;

	// animations
	--easeInOutBack: all 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}


*, *:after, *:before {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
}

html{
	font-size: 62.5%;
	scroll-behavior: smooth !important;
}

body{
	font-family: var(--font-stack) !important;
	font-size: var(--font-size-base);
	color: var(--primary-color);
	letter-spacing: var( --letter-spacing);
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    width: 100vw;
	font-weight: 500;
}

input,
textarea,
select,
textarea {
	font: normal var(--font-size-base --font-stack);
}

input,
textarea,
select {
	border: 1px solid #ccc;
	border-radius:var(--border-radius);
	color: var(--primary-color);
	display: inline-block;
	font-size: var(--font-size-base);
	line-height: 1;
	vertical-align: middle;
}

input[type="radio"],
input[type="checkbox"] {
	width: auto;
	padding: 0;
	margin: 0 10px 0 0;
	border: 0;
	box-shadow: none;
}

input[disabled],
input[readonly] {
	background-color: #f2f2f2;
	cursor: not-allowed;
	opacity: 0.3;
}


select {
	background-color: #ffffff;
	height: 58px;
}

input[type="file"] {
	height: auto;
	width: auto;
	padding: 0;
	border: none;
	box-shadow: none;
}

fieldset {
	margin: 0;
	padding: 0;
	display: block;
	border: none;
}

legend {
	padding: 0;
	display: block;
	font-size: var(--font-size-base);
	line-height: 1;
	margin: 0;
	color: var(--primary-color);
	width: 100%;
}

label {
	font-size: var(--font-size-base);
	display: inline-block;
	color: var(--primary-color);
    line-height: 1;
}


::-webkit-input-placeholder {
	color: #ccc;
}
::-moz-placeholder {
	color: #ccc;
}
:-ms-input-placeholder {
	color: #ccc;
}
:-moz-placeholder {
	color: #ccc;
}

.root {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	flex: 1 0 100%;
}


p a {
    text-decoration: inherit;
    border-bottom: 1px solid var(--ocean);

    &:hover{
        text-decoration: inherit;
        color: var(--indigo);
    }
}




// Safari 15+ displays a gray border while loading for next/image.
@supports (font: -apple-system-body) and (-webkit-appearance: none) { 
	img[loading="lazy"] { 
		clip-path: inset(0.6px) 
	} 
}

#sentry-feedback .widget__actor{
	background-color: #89abc1 !important;
}

#email-placement img{
	width: 100%;
	object-fit: contain;
	padding: 2rem;
}

.nosto_element, .lazyload-wrapper {
	width: 100%;
}


@media only screen and (max-width: --screen-lg ) {
     .bodyBlur{
         overflow:hidden;
     }
}

// Global Toasts: 
// progress bar color
.Toastify__progress-bar-theme--light {
	background: --sage;
}

.Toastify__toast-container--bottom-left{
	bottom: 4em;
}

.Toastify__toast-theme--light{
    background-color: rgba(#2e303b, .95);
    color: #fff;

	& button{
		color: #fff;
		opacity: 1;
	}
}

.Toastify__progress-bar--error{
	background: var(--terracotta);
}

[aria-hidden="true"]{
	& > a{
		display: none;
	}
}


// React Lazy
.LazyLoad {
	opacity: 0;
	transition: all .500s cubic-bezier(.59,.28,.83,.67);
	width: 100%;
}

.is-visible {
	opacity: 1;
}



/* -------------------------------------------------------------
	CMS VDS Styles 
	- theses are to be kept at a BARE MINIMUM!!!
	- only using because we cannot use dynamic component styles
	- DO NOT ADD TO THIS WITHOUT ARCHITECTURAL APPROVAL!!
---------------------------------------------------------------- */

.fs_1, .fs_2, .fs_3, .fs_4, .fs_5, .fs_6{
	display: flex
}

.fs_1{
	@extend %h1;
	@include font.dynamic-font(40, 54);
}

.fs_2{
    @extend %h2;
    @include font.dynamic-font(23, 33);
}

.fs_3{
    @extend %h3;
    @include font.dynamic-font(23, 33);
}

.fs_4{
    @extend %h4;
    @include font.dynamic-font(20, 24);
}

.fs_5{
    @extend %h5;
    @include font.dynamic-font(18, 22);
}

.fs_6{
    @extend %h6;
    @include font.dynamic-font(14, 18);
}


.quote {
    @extend %h1;
    @include font.dynamic-font(22, 40);
    letter-spacing: 0.4rem;
    text-transform: none;
    font-weight: normal;

    & > strong {
        font-weight: normal;
        color: var(--ocean);
    }
}

ul.list_styled, ol.list_styled {
	@extend %ul;	

	& li{
        margin-bottom: 2.1rem;
    }
}

// need to come back and fix this later
// -----------------------------------------------

@each $vdsColor, $color in $vds-colors {
	.text_#{$vdsColor} {
		color: $color	
	}

	.bg_#{$vdsColor}{
		background-color: $color
	}
}


%cmsButton{
	padding: 1.7rem calc(1vw + 17px);
	display: block;
	color: inherit !important;
	text-decoration: none !important;
	border: none !important;

	&:hover{
		color: inherit !important;
	}
}

.button_block{
	@extend %standard-button;
	@extend %button-animate;	
	text-decoration: none !important;
	padding: 0 !important;

	strong, b{
		font-weight: normal;
	}

	a{
		@extend %cmsButton;
	}
}

.button_outline{
	border-color: var(--charcoal) !important;
	background-color: transparent !important;
	color: var(--charcoal) !important;
}


p a:has(span.button_block){
	& .button_block{
		@extend %cmsButton;
		padding: 1.7rem calc(1vw + 17px) !important;
		color: var(--white) !important;

		&:hover{
			color: var(--white) !important;
		}

		&.bg_dove{
			color: var(--charcoal) !important;
		}
	}
}


.button_block.bg_dove{
	color: var(--charcoal) !important;
}

